var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validation-provider", {
    attrs: { mode: _vm.vmode, vid: _vm.vid, name: _vm.name, rules: _vm.rules },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ dirty, validated, valid, errors }) {
          return [
            _c(
              "b-form-group",
              {
                class: {
                  asterisk:
                    Object.keys(_vm.rules).includes("required") ||
                    (Object.keys(_vm.rules).includes("required_if") && !valid),
                },
                attrs: {
                  id: `input-group-${_vm.id}`,
                  label: _vm.label,
                  "label-for": `input-${_vm.id}`,
                },
              },
              [
                _c("b-form-input", {
                  style: { "text-align": "right" },
                  attrs: {
                    type: "text",
                    id: `input-${_vm.id}`,
                    name: `input-${_vm.id}`,
                    maxlength: _vm.maxlength,
                    value: _vm.viewVal,
                    "data-val": _vm.originalVal,
                    state: _vm.getValidationState({
                      dirty,
                      validated,
                      valid,
                      errors,
                    }),
                    "aria-describedby": `input-${_vm.id}-live-feedback`,
                    autocomplete: "off",
                    disabled: _vm.disabled,
                    placeholder: _vm.placeholder,
                    readonly: _vm.readonly,
                  },
                  on: {
                    input: _vm.setValue,
                    blur: _vm.onBlur,
                    keyup: _vm.onKeyUp,
                    "~change": function ($event) {
                      return _vm.onChange.apply(null, arguments)
                    },
                  },
                  nativeOn: {
                    keydown: function ($event) {
                      return _vm.onKeyDown.apply(null, arguments)
                    },
                    focus: function ($event) {
                      return _vm.onFocus.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "b-form-invalid-feedback",
                  { attrs: { id: `input-${_vm.id}-live-feedback` } },
                  [_vm._v(_vm._s(errors[0]))]
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }